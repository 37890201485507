import React, { useState, useEffect } from "react";
import "../../assets/sass/atoms/_select.scss";

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  id: string;
  selected: string;
  colorSelect: string;
  size: string;
  action?: string;
}

const actionMap = {
  changeLanguage: (value: string) => {
    if (value === "es") {
      window.location.replace(`/`);
    } else {
      window.location.replace(`/${value}`);
    }
  },
};

const Select: React.FC<SelectProps> = ({
  options,
  id,
  selected,
  colorSelect,
  size,
  action,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(selected);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    setIsDropdownOpen(false);

    if (action) {
      actionMap[action](value);
    }
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeAllSelect = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", closeAllSelect);
    return () => {
      document.removeEventListener("click", closeAllSelect);
    };
  }, []);

  return (
    <div className="dv-select" onClick={(e) => e.stopPropagation()}>
      <div id={`custom-select-${id}`} className="custom-select-container">
        <div
          className={`select-selected dv-select-${colorSelect} dv-select-${size}`}
          onClick={handleDropdownClick}
        >
          <span className="label">
            {options.find((option) => option.value === selectedOption)?.label}
          </span>
          <svg
            className="arrow"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 15L5 8.5L6.5 7L12 12.5L17.5 7L19 8.5L12 15Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div
          className={`select-items ${isDropdownOpen ? "" : "select-hide"} dv-select-${colorSelect}`}
        >
          {options.map((option, index) => (
            <div
              key={index}
              data-value={option.value}
              className={`item dv-select-${size} ${option.value === selectedOption ? "same-as-selected" : ""}`}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
        <select id={id} defaultValue={selectedOption} hidden>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
